import React, { useState } from 'react'
import './Checkout.css';

function Checkout() {

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const proceed = () => {
    window.location.href = 'payment/';
  }

  return (
    <div className='main-bg'>
      <div className="user-info">
        <h1 style={{ fontWeight: 'bold' }}>User Details</h1>
        <form className='information'>
          <div className='info'>
            <h3>Name: </h3><input type="text" className="input-box" placeholder="Enter your name" name='name' />
          </div>
          <div className='info'>
            <h3>Flat/Building/Society: </h3><input type="text" className="input-box" placeholder="Flat/Building/Society" name='address' /></div>
          <div className='info'>
            <h3>City: </h3><input type="text" className="input-box" placeholder="City" name='city' /></div>
          <div className='info'>
            <h3>pincode: </h3><input type="text" className="input-box" placeholder="Pincode" name='pincode' /></div>
          <div className='payment' style={{ height: 'auto', width: '90%', border: '2px solid black', borderRadius: '5px', background: 'white', padding: '5px' }}>
            <h1>Payment</h1>
            <p style={{ fontSize: '13px' }}>All Transaction are secure and encrypted</p>
            <div className='phonepay' style={{ height: 'auto', width: '90%', border: '2px solid black', borderRadius: '5px', display: 'flex', flexDirection: 'column', padding: '5px', marginLeft:'5%'}}>
              <h3>Phonepay payment Gateway (UPI, Cards and NetBanking) </h3>
              <div style={{fontSize:'14px'}}>
                <input
                  type="radio"
                  id="option1"
                  name="options"
                  value="option1"
                  checked={selectedOption === 'option1'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">UPI</label>
              </div>
              <div style={{fontSize:'14px'}}>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  value="option2"
                  checked={selectedOption === 'option2'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">VISA</label>
              </div>
              <div style={{fontSize:'14px'}}>
                <input
                  type="radio"
                  id="option3"
                  name="options"
                  value="option3"
                  checked={selectedOption === 'option3'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">Master Card</label>
              </div>
              <div style={{fontSize:'14px'}}>
                <input
                  type="radio"
                  id="option4"
                  name="options"
                  value="option4"
                  checked={selectedOption === 'option4'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">Cash</label>
              </div>
            </div>
            <p style={{paddingTop:'15px',fontSize:'12px'}}>After Clicking "Proceed to Pay" you will be redirected to PhonePay Payment Gateway (UPI, Cards & NetBanking) to complete your purchase securely.</p>
          </div>
          <button type='submmit' style={{ width: 'auto', height: '35px', fontSize: '18px', borderRadius: '15px', border: '2px solid black', background: 'white', paddingLeft: '10px', paddingRight: '10px' }} onClick={proceed}>Proceed to Pay</button>
        </form>
      </div>
      <div className="product-details">
        <h1>Product details</h1>
      </div>
    </div>
  )
}

export default Checkout
