import React from 'react'
import './Pc.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Product_category() {
    return (
        <div>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>
                        <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                        GujjuBoy Clothing
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/product/011' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                                <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            <div className="pc">
                <h2>Product and Category</h2><div className='line'></div>
                <p style={{ fontSize: '16px' }}>
                    <b>Unisex Regular / Round Neck T-shirt</b><br></br>
                    <b>Solid Shades: </b>Dive into a rainbow of 15 vibrant colors, perfect for every mood and occasion.<br></br>
                    <b>Anime Affection: </b>Express your love for anime with our exclusive collection of themed t-shirts.<br></br><br></br>
                    <b>Unisex Oversized T-shirt</b><br></br>
                    <b>Bold Basics: </b>Embrace the oversized trend with our tees, available in 10 striking colors that demand attention.<br></br>
                    <b>Divine Designs: </b>Add a touch of mystique with our god-inspired designs that make a statement.<br></br>
                    <b>Anime Allure: </b>Unleash your inner otaku with our captivating anime-themed tees.<br></br><br></br>
                    <b>Men’s Polo T-shirt</b><br></br>
                    <b>Sleek Sophistication: </b>Elevate your style game with our men's polo tees, available in 10 chic colors for a refined look.<br></br><br></br>
                    <b>Men’s Full Sleeve T-shirt</b><br></br>
                    <b>Cool Comfort: </b>Stay cozy and stylish with our full sleeve tees, available in 4 classic colors that never go out of style.<br></br>
                    <b>Anime Appeal: </b>Show off your favorite anime characters with our themed full sleeve tees.<br></br>
                    <b>Unisex Regular Hoodie</b><br></br>
                    <b>Cozy Chic: </b>Stay warm and fashionable with our regular hoodies featuring exclusive anime designs.<br></br>
                </p>
                <p style={{ fontSize: '16px' }}>We believe that fashion should be fun, expressive, and above all,
                    authentic. Each piece in our collection is a reflection of our commitment to bringing you the latest
                    trends without compromising on quality. So go ahead, explore our range, and discover the joy of feeling
                    trendy and authentic.
                </p>
            </div>
        </div>
    )
}

export default Product_category
