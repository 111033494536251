import React from 'react'
import './Return.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Return() {
    return (
        <div>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>
                        <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                        GujjuBoy Clothing
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/product/011' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                                <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
           <div className="about-return">
                <p style={{ fontSize: '16px' }}>
                    <h1>Hassle-Free Return Policy</h1><br></br>
                    At Gujju Clothing, we prioritize your satisfaction. If you're not completely happy with your purchase, you may return your item(s) within 7 days of delivery.
                    <br></br><br></br>
                    Return Process:<br></br>
                    1. Initiate a Return: Email us at gujjuclothing@gmail.com with your order details.<br></br>
                    2. Guidance: Our team will provide instructions to guide you through the return process.<br></br>
                    3. Refund: Once we receive the returned product, your refund will be processed within 2 days.<br></br>
                    <br></br>
                    Your satisfaction is our priority. For any queries or assistance, please feel free to contact us.
                </p>
            </div>
        </div>
    )
}

export default Return
