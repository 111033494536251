import React from "react";
import "./Payment.css";
import { FaArrowLeft } from "react-icons/fa6";

function Payment() {


    const back = async () => {
        window.location.href = 'checkout/';
    }
    return (
        <div className="payment-page">
            <div className="payment">
                <h1 style={{ fontWeight: 'bold' }}><FaArrowLeft className='back' style={{ marginRight: '20px' }} onClick={back} />Payment</h1>
            </div>
            <div className="options">
                <div className="payment-container">
                    <div className="Total">
                        <h1>order total ....</h1>
                    </div>
                    <h1 style={{ marginBottom: '20px', fontWeight:'bold' }}>Choose Payment Method</h1>
                    <div className="payment-method">
                        <img src="/Images/upi-icon.webp" style={{ height: '50px' }}></img>
                        <h2>Pay Using UPI</h2>
                    </div>
                    <div className="payment-method">
                        <img src="/Images/cards.png" style={{ height: '50px' }}></img>
                        <h2>Pay Using Cards</h2>
                    </div>
                    <div className="payment-method">
                        <img src="/Images/cod.png" style={{ height: '50px' }}></img>
                        <h2>Cash On Delivery</h2>
                    </div>
                    <div className="payment-method">
                        <img src="/Images/Netbanking.png" style={{ height: '50px' }}></img>
                        <h2>Net Banking</h2>
                    </div>
                    <div className="payment-method">
                        <img src="/Images/EMI.webp" style={{ height: '50px', borderRadius: '10px' }}></img>
                        <h2>EMI</h2>
                    </div>
                    <div className="payment-method">
                        <img src="/Images/wallet.png" style={{ height: '50px' }}></img>
                        <h2>Wallet</h2>
                    </div>
                    <button className="payment-button">Payment</button>
                </div>
            </div>
        </div>
    )
}

export default Payment
