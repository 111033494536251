import React from 'react'
import './About.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function About() {
    return (
        <div>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>
                        <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                        GujjuBoy Clothing
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/product/011' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                                <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            <div className="about">
                <h2>About us</h2><div className='line'></div>
                <p style={{fontSize:'16px'}}>Welcome to GujjuBoy Clothing, where fashion is not just a trend - it's a lifestyle! Specializing in trendy t-shirts and hoodies, we are your ultimate destination for style that speaks volumes.</p>
                <p style={{fontSize:'16px'}}>Founded by Bhavik Jamaliya, a fashion enthusiast and trendsetter, we are all about celebrating individuality with every stitch. Each piece in our collection is carefully curated to embody the essence of the GujjuBoy spirit—bold, authentic, and always on point.</p>
                <p style={{ margin: '0px' }}>Driven by our love for all things trendy and our commitment to quality, we strive to offer you the best in fashion.</p>
            </div>
        </div >
    )
}

export default About
