import React from 'react'
import './Product.css';
import freq from './Fetchreq';
//navbar
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'

function Landing() {
    const get_oversized_products = async () => {
        window.location.href = 'category/' + 'oversized'
    }


    const get_anime_products = async () => {
        window.location.href = 'category/' + 'anime'
    }

    const get_round_products = async () => {
        window.location.href = 'category/' + 'roundneck'
    }

    const get_polo_products = async () => {
        window.location.href = 'category/' + 'polo'
    }

    const get_fullsleeves_products = async () => {
        window.location.href = 'category/' + 'fullsleeves'
    }


    const products = freq("GET", "home")
    console.log(products);
    return (
        <div>
            {!products ? (
                <h1>Loading...</h1>
            ) : (products.length)}
            <div>
                <header>
                    <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
                </header>
                <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                    <Navbar.Brand href="/" style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/product/047' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="background">
                    <img src='/Images/GB_LOGO.png' className="cover" alt='' />
                </div>

                <div className="Unisex">
                    <p>Shop by Category</p>
                    <div className="line"></div>
                    <div className="all-items">
                        <div className="content">
                            <div className="all">
                                <img src="/Images/DSC07716.jpg" className="all-image" alt='' />
                                <button onClick={() => window.location.href = 'category/product/052'}>New Arrival</button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="all" onClick={() => get_anime_products()} >
                                <img src="/Images/anime_bg.jpg" className="all-image" alt='' />
                                <p style={{ display: 'flex', position: 'absolute', bottom: '110px', fontSize: '20px', fontWeight: 'bold' }}></p>
                                <button>Anime Products</button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="all" onClick={() => get_oversized_products()}>
                                <img src="/Images/DSC06650.jpg" className="all-image" alt='' />
                                <button> Oversized T-Shirts </button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="all" onClick={() => get_round_products()} >
                                <img src="/Images/DSC06619.jpg" className="all-image"  alt='' />
                                <p style={{ display: 'flex', position: 'absolute', bottom: '110px', fontSize: '20px', fontWeight: 'bold' }}></p>
                                <button>Round Neck T-Shirt</button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="all" onClick={() => get_polo_products()}>
                                <img src="/Images/DSC06991.jpg" className="all-image"  alt='' />
                                <p style={{ display: 'flex', position: 'absolute', bottom: '110px', fontSize: '20px', fontWeight: 'bold' }}></p>
                                <button>Men's Polo</button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="all" onClick={() => get_fullsleeves_products()}>
                                <img src="/Images/DSC07070.jpg" className="all-image" alt='' />
                                <p style={{ display: 'flex', position: 'absolute', bottom: '110px', fontSize: '20px', fontWeight: 'bold' }}></p>
                                <button>Men's Full Sleeves</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-information">
                        <p>Necessary Information</p>
                        <a href='https://www.gujjuboyclothing.com/about'>About Us</a>
                        <a href='https://www.gujjuboyclothing.com/Terms-Condition'>Terms & condition</a>
                        <a href='https://www.gujjuboyclothing.com/privacy-policy'>Privacy Policy</a>
                        <a href='https://www.gujjuboyclothing.com/pc'>Product Categories</a>
                        <a href='https://www.gujjuboyclothing.com/return'>Return Policy</a>
                        <a href='https://www.gujjuboyclothing.com/conc'>Contact Us</a>
                        
                        <div className="social">
                            <a href='https://www.instagram.com/gujjuboy.clothing?igsh=OTdyOXBnNTc2Zm10'><img src='/Images/download-removebg-preview.png' alt='' /></a>
                            <a href='https://www.youtube.com/@bhavikgujjuboy'><img src='/Images/youtube.jpg' alt='' /></a>
                        </div>
                        <p style={{ textAlign: 'center' }}>GUJJU BOY CLOTHING</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing
