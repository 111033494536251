import React from 'react'
import './Userprofile.css'
//navbar
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import freq from './Fetchreq';
import Cookies from "universal-cookie";

function Userprofile() {
    let i1, i2, i3, i4, i5, i6;
    const cookies = new Cookies();
    const handle = (e) => {
        e.preventDefault();
        i1 = e.target.i1.value;
        i2 = e.target.i2.value;
        i3 = e.target.i3.value;
        i4 = e.target.i4.value;
        i5 = e.target.i5.value;
        i6 = e.target.i6.value;
        const formData = { 'n1': i1, 'n2': i2, 'n4': i4, 'n5': i5, 'i6': i6 }
        console.log(formData);
        // try {
        //   const response = await fetch('', {
        //     mode: 'cors',
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(formData),
        //   });
        //   if (response.ok) {
        //     const responseData = await response.json();
        //     console.log(responseData);
        //   }
        //   else {
        //     console.error('No Response');
        //   }
        // } catch (error) {
        //   console.error('Error sending data:', error);
        // }
    }

    const logout = (e) =>{
        e.preventDefault();
        cookies.remove("token");
    }
    return (
        <div className='body'>
            <header>
                <p>5% off on prepaid orders!</p>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '39px', height: 'auto', alignItems: 'center', padding: '0' }}>
                <Navbar.Brand href="#home" style={{ color: 'black' }}>
                    <img src='/Images/bg.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                    GB Clothing
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown title="Category" id="collapsible-nav-dropdown" style={{ color: 'black', fontSize: '18px' }}>
                            <NavDropdown.Item href="/" >
                                Oversized Tshirt
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Roundneck Tshirt
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Polo Tshirt
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Sweartshirt
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/" style={{ color: 'black', fontSize: '18px' }}>
                            Cart
                        </Nav.Link>
                        <Nav.Link href="/" style={{ color: 'black', fontSize: '18px' }}>
                            Favourite
                        </Nav.Link>
                        <Nav.Link href="/" style={{ color: 'black', fontSize: '18px' }}>
                            About
                        </Nav.Link>
                        <Nav.Link href="login" style={{ color: 'black', fontSize: '18px' }}>
                            Profile
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="details">
        <p>Terms and Condition</p><div className='line' />
        <p style={{ fontSize: '16px' }}>Thank you for shopping with GujjuBoy Clothing. Please read the
          following terms and conditions carefully before placing your order.</p>
        <h5>Order Delivery</h5>
        <p style={{ fontSize: '16px' }}>Your order will be processed and delivered within 5-7 business days from the date of purchase.
          We strive to ensure timely delivery; however, please note that delivery times may vary depending
          on your location and external factors beyond our control.
        </p>
        <h5>Return Policy</h5>
        <p style={{ fontSize: '16px' }}>We offer a hassle-free return policy. If for any reason you are not satisfied with your purchase,
          you may return the item's within 7 days of delivery for a full refund or exchange. To initiate a return,
          please contact us at gujjuclothing@gmail.com and provide your order details. Our team will guide you through
          the return process.
        </p>
        <h5>Delivery Coverage</h5>
        <p style={{ fontSize: '16px' }}>We offer delivery services across India. Delivery is free of charge for all orders shipped within India.
          There are no hidden fees or additional costs.
        </p>
        <h5>Shipping Information</h5>
        <p style={{ fontSize: '16px' }}>Upon placing your order, you will receive a confirmation email with your order details and tracking information.
          Please ensure that the shipping address provided is accurate and complete to avoid any delays or issues with delivery.
        </p>
        <p style={{ fontSize: '16px' }}>
          By placing an order with GujjuBoy Clothing, you agree to abide by these terms and conditions. We reserve the right to
          amend or update these terms and conditions when necessary.
        </p>
      </div>
        </div>
    )
}

export default Userprofile
