import React from 'react'
import './Contact.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
function Contact() {
    return (
        <div>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                <Navbar.Brand href="/" style={{ color: 'white' }}>
                    <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                    GujjuBoy Clothing
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/product/011' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='http://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                                <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="about">
                <h2>Contact</h2><div className='line'></div>
                <p style={{fontSize:'16px'}}>If you have any questions or concerns regarding your order, delivery, or return process, please don’t hesitate to reach out to our customer service team.
                    You can contact us via email at <a href = "mailto:gujjuclothing@gmail.com"> </a>  or by phone <a href="tel:8655418681"></a>. Our team is will assist you promptly.
                </p>
                <p style={{fontSize:'16px'}}>Thank you for choosing GujjuBoy Clothing. We appreciate your trust in us and look forward to serving you again in the future.</p>
            </div>
        </div>
    )
}

export default Contact
