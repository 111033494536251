import React, { useEffect } from 'react'

function Category() {

  const url = () => 
  {
    const path = window.location.pathname;
        const tryid = path.split('/')
        const id = tryid[tryid.length - 1]
        // console.log(typeof (id))
        // setid(id);
        console.log(id)
  }

  useEffect(() => {
    url();
  }, [])

  return (
    <div>
      <h1>Category page</h1>
    </div>
  )
}

export default Category
