import { React, useEffect, useState } from 'react'
import './Specific.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ReactLoading from "react-loading";

function Single() {

    // const [id, setid] = useState('');
    const [product, setproduct] = useState({});
    const [size, getSize] = useState('');
    const [imgSave, setimgSave] = useState([])
    const [desSave, setdesSave] = useState([])
    const [isValid, setisValid] = useState(false);
    const [sizeMessage, setSizeMessage] = useState('');

    useEffect(() => {
        fetch1()
    }, [])

    function imgDisplay(imgArray) {
        let imgSet = [];
        let imgSrc;
        for (let i = 0; i < imgArray.length; i++) {
            imgSrc = <img src={imgArray[i]} alt='product image'></img>
            imgSet.push(imgSrc);
        }
        return imgSet;
    }
function desDisplay(desArray) {
        let desSet = [];
        let desSrc;
        for (let i = 0; i < desArray.length; i++) {
            desSrc = <li>{desArray[i]}</li>
            desSet.push(desSrc);
        }
        return desSet;
    }

    const fetch1 = async () => {
        const path = window.location.pathname;
        const tryid = path.split('/')
        const id = tryid[tryid.length - 1]

        const ww = await fetch('https://api.gujjuboyclothing.com/product/' + id, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },

        }).then(res => res.json())
            .then(data => {
                setproduct(data.product);
                setimgSave(imgDisplay(data.product.pImage));
                setdesSave(desDisplay(data.product.pDescription));
                setisValid(true)
            })
    }

    function setSize(gg) {
        getSize(gg);
    }


    const redirectToWhatsApp = () => {
        if(size == ""){
		let text = "&nbsp;Please select a size!";
        	setSizeMessage(<div dangerouslySetInnerHTML={{ __html: text }} style={{display:'flex',marginTop:'8px',color:'red',fontWeight:'bold'}}></div>);
	        return;
        }
        // Construct the WhatsApp URL with the appropriate parameters
        const phoneNumber = '+918169867309'; // Replace this with the recipient's phone number
        const message = `Order a ${product.pName} \nSize: ${size}`; // Replace this with your message
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

        // Redirect the page to the WhatsApp URL
        window.location.href = 'checkout';
    }
    return (<>{!isValid ? (<div className='hh'><ReactLoading type="spin" color="#0000FF" height={100} width={50} /></div>) : (
        <div className='body'>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                <Navbar.Brand href="/" style={{ color: 'white' }}>
                    <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                    GujjuBoy Clothing
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/product/047' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
				    <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="product">
                <div className="image-container">
                    {imgSave.length == 0 ? (<>
                        <p> loading... </p>
                    </>) : (imgSave)}
                </div>
                <div className="details">
                    <h1>{product.pName}</h1>
                    <p>
			<ul style={{ listStyle: "disc", display: "flex", flexDirection: "column" }}>
                            {desSave}
                        </ul>
                    </p>
                    <div className="size-button">
                        <button onClick={() => setSize('S')}>S</button>
                        <button onClick={() => setSize('M')}>M</button>
                        <button onClick={() => setSize('L')}>L</button>
                        <button onClick={() => setSize('XL')}>XL</button>
                        <button onClick={() => setSize('XXL')}>XXL</button>
                    </div>
                </div>

               <h2 style={{ marginTop: '8px' }}> <span style={{marginTop:'2px',marginLeft:'8px',fontSize:'20px'}}> {product.pID == '047'? (<h4> <s style={{marginRight: '10px'}}> Rs. 1199 </s> </h4>) : (<></>) } </span>  Rs. {product.pMRP} <span style={{marginTop:'2px',marginLeft:'8px',fontSize:'20px'}}>{product.pCategory == 'anime' || product.pID == '047' ? (<> <p> +Free delivery !!! </p> </>) : (<>  </>)} </span></h2>
                <div className="buy">
                    <button style={{ background: 'white', fontWeight: 'bold' }}><a href='https://www.gujjuboyclothing.com/checkout'> Buy Now </a></button>
                    {sizeMessage}
                </div>
            </div>
            <div className="single-footer">
                <div className="footer-information">
                    <p>Necessary Information</p>
                    <a href='http://www.gujjuboyclothing.com/about'>About Us</a>
			<a href='https://www.gujjuboyclothing.com/Terms-Condition'>Terms & condition</a>
                        <a href='https://www.gujjuboyclothing.com/privacy-policy'>Privacy Policy</a>
                        <a href='https://www.gujjuboyclothing.com/pc'>Product Categories</a>
                        <a href='https://www.gujjuboyclothing.com/return'>Return Policy</a>
                        <a href='https://www.gujjuboyclothing.com/conc'>Contact Us</a>
                        
                    <div className="social">
                        <a href='https://www.instagram.com/gujjuboy.clothing?igsh=OTdyOXBnNTc2Zm10'><img src='/Images/download-removebg-preview.png' alt='' /></a>
                        <a href='https://www.youtube.com/@bhavikgujjuboy'><img src='/Images/youtube.jpg' alt='' /></a>
                    </div>
                    <p style={{ textAlign: 'center' }}>GUJJU BOY CLOTHING</p>
                </div>
            </div>
        </div>)}</>
    )
}

export default Single
