import { React, useEffect, useState } from 'react'
import './Allproduct.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ReactLoading from "react-loading";

function AllProducts() {

    const [data, setdata] = useState([]);
    const [isValid, setisValid] = useState(false);

    const get_oversized_products = async () => {
        const path = window.location.pathname;
        const tryid = path.split('/')
        const id = tryid[tryid.length - 1]
        // console.log(typeof (id))
        // setid(id);
        console.log(id)
        const response = await fetch('https://api.gujjuboyclothing.com/category/' + id, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(data => {
            setdata(data)
            setisValid(true)
            console.log(data.products)
        });
    }

    function handleclick(id) {
        window.location.href = 'product/' + id;
        console.log(id)
    }


    useEffect(() => {
        get_oversized_products();
    }, [])

    return (<>{!isValid ? (<div className='hh'><ReactLoading type="spin" color="#0000FF" height={100} width={50} /></div>) : (
        <>
            <header>
                <marquee><span style={{ marginRight: '40px' }}>🎉 5% off on prepaid orders</span><span style={{ marginRight: '40px' }}>🎉 10% off on order above 999rs</span><span style={{ marginRight: '40px' }}>🎉 20% off on order above 1999rs</span></marquee>
            </header>
            <Navbar collapseOnSelect expand="lg" className="bg-secondary" style={{ display: 'flex', position: 'absolute', margin: '0', top: '30px', height: 'auto', alignItems: 'center', padding: '0', zIndex: '1' }}>
                <Navbar.Brand href="/" style={{ color: 'white' }}>
                    <img src='/Images/GB_LOGO.png' style={{ height: '50px', borderRadius: '25px', marginRight: '10px' }} />
                    GujjuBoy Clothing
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/product/047' style={{ color: 'white', fontSize: '18px' }} >
                            NEW ARRIVAL
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/anime' style={{ color: 'white', fontSize: '18px' }} >
                            ANIME
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/oversized' style={{ color: 'white', fontSize: '18px' }} >
                            OVERSIZED T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/roundneck' style={{ color: 'white', fontSize: '18px' }}>
                            ROUND NECK T-SHIRT
                        </Nav.Link>
                        <Nav.Link href='https://www.gujjuboyclothing.com/category/polo' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S POLOS
                        </Nav.Link>
                                <Nav.Link href='https://www.gujjuboyclothing.com/category/fullsleeves' style={{ color: 'white', fontSize: '18px' }}>
                            MEN'S FULLSLEEVES
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div class="Unisex-product">

                {data.products?.map((e) => {
                    return (
                        <>
                            <div class="content" onClick={() => handleclick(e.pID)}>
                                <div class="xx">
                                    <img src={e.pImage[0]} class="all-image" onclick="location.href='cart.html'" alt='' />
                                </div>
                                <div className='gap'></div>
                                <p style={{
                                    display: 'flex',
                                    width: '140px',
                                    flexWrap: 'wrap',
                                    textAlign: 'center',
                                }}> {e.pName}<br />Rs. {e.pMRP}</p>
                        </div >
                    </>
            )
            })}
        </div>
    </>
    )
}</>
    )
}

export default AllProducts
